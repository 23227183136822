import React from 'react';
import './Singin.style.scss'


export function Singin() {
  return (
    <div className='ds-form'>
      <form>
        <div class="container">
          <h1>Sign Up</h1>
          <p>Please fill in this form to create an account.</p>

          <label for="email"><b>Email</b></label>
          <input type="text" placeholder="Enter Email" name="email" required />

          <label for="psw"><b>Password</b></label>
          <input type="password" placeholder="Enter Password" name="psw" required />

          <label for="psw-repeat"><b>Repeat Password</b></label>
          <input type="password" placeholder="Repeat Password" name="psw-repeat" required />

          <label for="remember">Remember me</label>
          <input type="checkbox" checked="checked" name="remember" />


          <div class="clearfix">
            <button type="submit" class="signupbtn">Sign Up</button>
          </div>
        </div>
      </form>
    </div>
  )
}