import { Main } from "../common/Main";


const Home = () => {
  return (
    <div className="home">
      <div>Home</div>
      <Main />
    </div>
  );
};

export default Home;
