import React from 'react';
import {Logo} from './Logo';
import './Footer.style.scss';


export function Footer() {
  return (
    <div className='ds-footer'>
      <Logo />
    </div>
  )
}