import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { PageHome, PageTests } from './components/Test';
import Home from './components/pages/Home';
import { PageLayout } from './components/common/PageLayout';
import {Singin} from './components/common/Singin';
import UiDemo from './components/pages/UiDemo';
import Page404 from './components/pages/Page404';
import { Examples as ExamplesBootstrapUI } from './components/ui/bootstrap/Examples';


function App() {
  return (
    <div className="App">
      <PageLayout>
        <Routes>
          <Route path="/" element={<PageHome />} />
          <Route path="tests" element={<PageTests />} />
          <Route path="home" element={<Home />} />
          <Route path="singin" element={<Singin />} />
          <Route path="uidemo" element={<UiDemo />} />
          <Route path="bootstrap" element={<ExamplesBootstrapUI />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </PageLayout>

    </div>
  );
}

export default App;
