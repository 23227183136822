import React from 'react';
import './PageLayout.style.scss';
import { Header } from './Header';
import { Footer } from './Footer'


export function PageLayout({children}) {
  return (
    <div className='page-layout'>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  )
}