import React, { useState } from 'react';
import './Block1.style.scss';


export function Block1() {
const [color, setColor] = useState(null);

const handleSelect = (e) => {
  const value = e.target.dataset.tt;
  setColor(value);
}


  return (
    <div className='block1'>
      <Block2 onSelectColor={handleSelect}/>
      <Block3 color={color}/>
    </div>
  )
}

function Block2({onSelectColor}) {
  return (
    <div className='block2'>
      <div className='block2-1' data-tt="green" onClick={onSelectColor}>green</div>
      <div className='block2-2' data-tt = "yellow" onClick={onSelectColor}>yellow</div>
      <div className='block2-3' data-tt= "red" onClick={onSelectColor}>red</div>
    </div>

  )
}
function Block3(props) {
  return (
    <div className={`block3 ${props.color}`}>
      lklkkjllkj
    </div>
  )
}