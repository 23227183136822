import React, {useState} from "react";
import { DropdownSelect } from "../ui/dropdowns/DropdownSelect";
import { Block1 } from "../common/Block1";

const mass = [
  {
    value: 1,
    text: 'EN',
  },
  {
    value: 2,
    text: 'UKR',
  },
  {
    value: 3,
    text: 'dsadasdas',
  },
];

const UiDemo = () => {
  const [value, setValue] = useState(null);

  const handleSelect = (item) => {
    setValue(item.value);
  }
  return (
    <div className="ui">
      <DropdownSelect value={value} items = {mass} onSelect = {handleSelect}/>
      <Block1 />
    </div>

  );
};

export default UiDemo;