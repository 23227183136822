import React from 'react';
import "./Main.style.scss";

export function Main() {
  return (
  <div className='ds-main'>
    <div class="row">
  <div class="column">
    <h2>Column 1</h2>
    <p>Some text..</p>
  </div>
  <div class="column">
    <h2>Column 2</h2>
    <p>Some text..</p>
  </div>
</div>

<div class="row">
  <div class="column">
    <h2>Column 3</h2>
    <p>Some text..</p>
  </div>
  <div class="column" >
    <h2>Column 4</h2>
    <p>Some text..</p>
  </div>
</div>
</div>
  )
}