import React, { useState } from 'react';
import "./Header.style.scss";
import {Menu} from './Menu';
import {Logo} from './Logo';
import { List } from './List';
import { LanguageSelect } from '../ui/dropdowns/LanguageSelect';


export function Header() {
  const [language, setLanguage] = useState("EN");
  return (
    <div className='ds'>
      <Logo />
      
      <div className='right-block'>
      <LanguageSelect className = 'lang-block' onSelect={setLanguage} lang = {language}/>
      <Menu />
      </div>
    </div>
  )
}