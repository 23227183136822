import React, { Children, useState } from 'react';
import './DropdownBox.style.scss';


export function DropdownBox({children, trigger}) {
  const [isActive, setActive] = useState(false);

  const handleClick = () => {
    setActive(!isActive);
  }
  const handleClose = () => {
    setActive(false);
  }

  return (
    <div className='ui-dropdown-box'>
      <DropdownTrigger onClick={handleClick} trigger = {trigger}/>
      {isActive && (
        <div className='ui-dropdown'>
          {children(handleClose)}
        </div>
      )}

    </div>
  )
}

function DropdownTrigger({ onClick, trigger }) {
  return (
    <div className='ui-dropdown-trigger btn btn-primary dropdown-toggle' onClick={onClick} >
      {trigger}
    </div>
  )
}


