import React from 'react'
import IconMi from './IconMi';
import './IconMiExamples.style.scss';

const IconMiExamples = () => {
  return (
    <div className="icon-mi-examples">
      <div>
        <h3>Изменяем размер</h3>
        <IconMi iconId='cloud' className='md-18' />
        <IconMi iconId='cloud' className='md-24' />
        <IconMi iconId='cloud' />
        <IconMi iconId='cloud' className='md-36' />
        <IconMi iconId='cloud' className='md-48' />
      </div>
      <div>
        <h3>Изменяем цвет</h3>
        <IconMi iconId='apps' className='md-48 green' />
        <IconMi iconId='delete_forever' className='md-48' style={{ color: 'yellow' }} />
        <IconMi iconId='view_timeline' className='md-48' />
        <IconMi iconId='library_add' className='md-48' />
        <IconMi iconId='dataset' className='md-48' />
      </div>
      <div>
        <h3>Outlined</h3>
        <IconMi isOutlined iconId='apps' className='md-48 green' />
        <IconMi isOutlined iconId='delete_forever' className='md-48' style={{ color: 'yellow' }} />
        <IconMi isOutlined iconId='view_timeline' className='md-48 material-icons-outlined' />
        <IconMi isOutlined iconId='library_add' className='md-48' />
        <IconMi isOutlined iconId='dataset' className='md-48' />
      </div>
    </div>
  )
}

export default IconMiExamples;
