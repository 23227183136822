import React, { CSSProperties } from 'react'
import cn from 'classnames';
import './IconMi.style.scss';

export interface IIconMi {
  /** Идентификатор иконки из material-icons */
  iconId: string;
  /** Доп.класс для компонента */
  className?: string;
  style?: CSSProperties;
  isOutlined?: boolean;
}
/**
 * Иконка из МатериалИконс Гугла
 * 
 * @description Описание работы с иконками Гугла на странимце официальной 
 * докумектации https://developers.google.com/fonts/docs/material_icons
 * 
 * Список иконок: https://fonts.google.com/icons
 */
const IconMi: React.FC<IIconMi> = ({ iconId, className, style, isOutlined=false }) => {
  const miClass = isOutlined ? 'material-icons-outlined' : 'material-icons';
  return (
    <i className={cn(
      'icon-mi', 
      miClass,
      `icon-mi__${iconId}`, 
      className
      )}
      style={style}
    >
      {iconId}
    </i>
  )
}

export default IconMi;
