import React, { useState } from 'react';
import { DropdownBox } from './DropdownBox';
import './DropdownSelect.style.scss';
import cn from 'classnames';


export function DropdownSelect({ value, items, onSelect }) {


  const handleClick = (item, close) => () => {
    console.log(item);
    onSelect(item);
    close();
  }

  return (
    <div className='ui-dropdown-select dropdown'>
      <DropdownBox trigger={<div className='lang'>{value}</div>}>
        {(handleClose) => (
          <div className='dropdown-menu'>
            {items.map(item => {
              return (
                <div
                  className={cn('dropdown-item ', {

                    active: value === item.value
                  })}
                  onClick={handleClick(item, handleClose)}
                >
                  {item.text}
                </div>
              )
            }
            )}

          </div>

        )}


      </DropdownBox>

    </div>
  )
}