import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import ExampleAlerts from './ExampleAlerts';
import ExampleButtons from './ExampleButtons';

export const Examples = () => {
  return (
    <Container className="p-3">
    <Container className="p-5 mb-4 bg-light rounded-3">
      <h1 className="header">Welcome To React-Bootstrap 5</h1>
      
      <Button 
        variant="link" 
        href='https://react-bootstrap.github.io/components/alerts'
        target='blank'
      >
        Описание компонент Bootstrtap
      </Button>

      <Button 
        variant="link" 
        href='https://www.w3schools.com/bootstrap5/index.php'
        target='blank'
      >
        Bootstrap 5 Tutorial
      </Button>

      <Nav>
        <Nav.Item>
          <Nav.Link href="/">Home</Nav.Link>
        </Nav.Item>
      </Nav>
      <ExampleAlerts />
      <ExampleButtons />
    </Container>
  </Container>
  )
}
