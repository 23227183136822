import React from 'react'
import { add, sub } from './utils';
import './Test.style.scss';
import { Link } from "react-router-dom";
import { IconMiExamples } from './ui/images/IconMi';
import Nav from "react-bootstrap/Nav";

export function Test1() {
  return (
    <div>Test1</div>
  )
}

export const Test2 = () => {
  return (
    <div>Test2</div>
  )
}

export class Test3 extends React.Component {
  render() {
    return (
      <div>Test3</div>
    );
  }
}

export class Test4 extends React.Component {
  state = { value: 15 }
  
  handleClickAdd = (e) => {
    this.setState({
      value: Number(add(this.state.value, 1)),
    })
  }

  handleClickSub = (e) => {
    this.setState({
      value: Number(sub(this.state.value, 1)),
    })
  }

  render() {
    return (
      <div className='test-4'>
        <div className='test-4__title'>Test4</div>
        <div>{this.state.value}</div>
        <button className='test-4--button-1' onClick={this.handleClickAdd}>"+"</button>        
        <button className='test-4__button-2' onClick={this.handleClickSub}>"-"</button>        
      </div>
    );
  }
}

export const PageHome = () => {
  return (
    <div>
      <h1>Home</h1>
      <Nav>
        <Nav.Item>
          <Nav.Link href="/tests">Tests</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/home">Home</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/uidemo">UiDemo</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/bootstrap">Bootstrap examples</Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  )
}

export const PageTests = () => {
  return (
    <div>
      <Test1 />
      <Test2 />
      <Test3 />
      <Test4 />
      <Link to={"/"}>Home</Link>
      <IconMiExamples />
    </div>
  )
}
