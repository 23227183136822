import React from 'react';
import { DropdownSelect } from './DropdownSelect';

const languages = [
{
  value: "EN",
  text:  "English",
},
{
  value: "UK",
  text:  "Українська",
},
{
  value: "RU",
  text:  "Руский",
},

]

export function LanguageSelect({ lang, onSelect }) {

  const handleSelect = (item) => {
    onSelect(item.value);
  }


  return (
   <div className='language-select'>
    <DropdownSelect value={lang} items = {languages} onSelect = {handleSelect} />

   </div>
  )
}