import React from 'react';
//import "./Header.style.scss";

export function Menu() {
  return (
    <div className='ds-menu'>
      <a href="./singin">Sing - in </a>
        <div className='menu'>Menu</div>
      
    </div>
  )
}